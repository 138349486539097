import * as React from 'react'
import { useI18n } from 'context/I18nProvider'
import AsyncSelect from 'components/UI/AsyncSelect'

export interface BaseAsyncSelectProps {
  name: string
  url: string
  label: string
  className?: string
  mapper?: (opt: any) => object
  styles?: {
    option: (provided: any, state: any) => any
  } | {
    [key: string]: (styles: any) => any // utils/select
  }
  apiPlatform?: boolean
  urlParams?: string
}

export default function BaseAsyncSelect({
  name,
  url,
  urlParams = '',
  label,
  className = '',
  ...props
}: BaseAsyncSelectProps) {
  const { i18n } = useI18n()

  return (
    <div className={className}>
      <AsyncSelect
        id={name}
        placeholder={i18n(label)}
        url={`${url}${urlParams}`}
        isMulti
        {...props}
      />
    </div>
  )
}
